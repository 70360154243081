









































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import ArrowIcon from './ArrowIcon.vue'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'
import { CONSENTS_COMPONENT_CONFIG_MAP, CONSENTS_COMPONENT_KEY } from './Consents.config'
import { ConsentProps } from './Consents.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<Consents>({
  name: 'Consents',
  components: {
    VClamp: () => import(/* webpackChunkName: "vue-clamp" */ 'vue-clamp'),
    ArrowIcon
  },
  created () {
    this.config = this.getComponentConfig(CONSENTS_COMPONENT_KEY, CONSENTS_COMPONENT_CONFIG_MAP)

    this.isOpen = this.defaultOpen || !this.useAccordion
    this.isVisible = !this.useAccordion
  },
  mounted () {
    if (this.siteService && this.siteService?.getActiveSiteUrls) {
      this.pages = this.siteService?.getActiveSiteUrls(true) || []
    }
    window.addEventListener('resize', this.setMaxHeight)
    setTimeout(() => {
      this.setMaxHeight()
    }, 500)
  }
})
export class Consents extends Mixins(StructureConfigurable) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Boolean, required: false, default: false })
  public readonly defaultOpen!: boolean

  @Prop({ type: Array, required: false, default: [] })
  public readonly consents!: ConsentProps[]

  @Prop({ type: Function, required: true })
  public readonly getErrors!: (name: string) => string[] | undefined

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isAccordion!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDisabled!: boolean

  @Prop({ type: Boolean, default: true })
  public readonly showRodo!: boolean

  @Prop({ type: Boolean, default: false })
  public readonly useClampedLabels!: boolean

  @Prop({ type: String, required: false, default: 'shared.consents' })
  public readonly translationLabel!: string

  @PropSync('payload', { type: Object, required: true })
  public _payload!: AnyObject

  public pages: Record<string, string> = {}

  /**
   * Clamp mechanic.
   * @param key
   */
  public expandedGroup: string[] = []
  public isOpen = false
  public isVisible = false
  public maxHeight = 0

  public get checkAll (): boolean {
    return this.consents.every((key: AnyObject) => {
      return typeof this._payload[key.option] !== 'undefined' && this._payload[key.option]
    })
  }

  public set checkAll (checked: boolean) {
    const newConsents = this.consents.reduce((acc, consent) => ({
      ...acc,
      [consent.option]: checked
    }), {})

    this._payload = {
      ...this._payload,
      ...newConsents
    }
  }

  /**
   * Accordion part
   */
  public get useAccordion () {
    return this.isAccordion && this.getConfigProperty<boolean>('useAccordion')
  }

  public isExpanded (key: string): boolean {
    return this.expandedGroup.includes(key)
  }

  public expand (key: string): void {
    if (this.isExpanded(key)) {
      this.expandedGroup.splice(this.expandedGroup.indexOf(key), 1)
    } else {
      this.expandedGroup.push(key)
    }

    this.setMaxHeight()
  }

  public setMaxHeight () {
    const contentRef = this.$refs.contentRef as HTMLDivElement
    if (contentRef) {
      contentRef.style.maxHeight = ''
      this.maxHeight = contentRef.scrollHeight
    }
  }

  public onClick (isOpen: boolean) {
    if (!this.useAccordion) return

    this.isOpen = isOpen

    setTimeout(() => {
      this.isVisible = isOpen
    }, 500)
  }
}

export default Consents
