/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export const CONSENTS_COMPONENT_KEY = 'ConsentComponent'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export const CONSENTS_COMPONENT_CONFIG_MAP = {
  useAccordion: false
}
