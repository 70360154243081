




















































































import { AnyObject } from '@movecloser/front-core'
import { Component, Inject, Prop, PropSync, Vue } from 'vue-property-decorator'
import { SubscribeConfig } from '@kluseg/trackify/dist/main'

import Consents from '../../../front/shared/molecules/Consents/Consents.vue'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../support'
import { HeadingFormData } from '../../partials'

import { NewsletterFormState } from '../Newsletter.contracts'
import { NewsletterModuleConfig } from '../Newsletter.config'
import { FormOptions } from '../../../front/shared/services/selenaForms'
import { SelectItem } from '../../../dsl/atoms/Select'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component({
  name: 'Extended',
  components: { Consents }
})
export class Extended extends Vue {
  @PropSync('configuration', { type: Object, required: true })
  public readonly _config!: NewsletterModuleConfig

  @Prop({ type: Object, required: true })
  public readonly data!: AnyObject

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly hasAllRequiredFields!: boolean

  @Prop({ type: Function, required: true })
  public readonly clearState!: boolean

  @Prop({ type: Function, required: true })
  public readonly subscribe!: boolean

  @PropSync('formData', { type: Object, required: true })
  public readonly _formData!: SubscribeConfig

  @Prop({ type: Array, required: true })
  public readonly describedConsents!: AnyObject[]

  @Prop({ type: Object, required: true })
  public readonly occupationOptions!: FormOptions

  @Prop({ type: String, required: false, default: null })
  public readonly state?: NewsletterFormState

  @Prop({ type: String, required: true })
  public readonly theme!: string

  @Prop({ type: String, required: true })
  public readonly message!: string

  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  protected readonly isMobile!: () => boolean

  public getErrors () { return [] }

  public get heading (): HeadingFormData | undefined {
    if (!this.data.content.heading) {
      return
    }

    return this.data.content.heading
  }

  public get description (): string | undefined {
    if (!this.data.content.description) {
      return
    }
    return this.data.content.description
  }

  public get occupationTypeOptions (): SelectItem[] {
    const typeOptions = this.occupationOptions.type || []
    return typeOptions.map((item: AnyObject) => {
      if (item && 'label' in item && 'value' in item) {
        return {
          label: String(this.$t(item.label as string)),
          value: item.value
        }
      } else {
        return {
          label: '',
          value: ''
        }
      }
    })
  }

  public get NewsletterFormState () {
    return NewsletterFormState
  }
}

export default Extended
